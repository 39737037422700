import plugin_vue3_A0OWXRrUgq from "/Users/dreckr/workspace/nuxt-ember/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_4sVQNw7RlN from "/Users/dreckr/workspace/nuxt-ember/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/Users/dreckr/workspace/nuxt-ember/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/Users/dreckr/workspace/nuxt-ember/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/dreckr/workspace/nuxt-ember/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/Users/dreckr/workspace/nuxt-ember/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BqLkNUef68 from "/Users/dreckr/workspace/nuxt-ember/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_8SbxDRbG6Y from "/Users/dreckr/workspace/nuxt-ember/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/dreckr/workspace/nuxt-ember/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_yVLowv6hDl from "/Users/dreckr/workspace/nuxt-ember/node_modules/nuxt/dist/app/plugins/payload.client.js";
import customer_ViIGNqCnbl from "/Users/dreckr/workspace/nuxt-ember/src/plugins/customer.ts";
import error_t9vrANEheK from "/Users/dreckr/workspace/nuxt-ember/src/plugins/error.ts";
import fontAwesome_i1V8MuBbQI from "/Users/dreckr/workspace/nuxt-ember/src/plugins/fontAwesome.ts";
import gtm_client_Hp0PRXEnoA from "/Users/dreckr/workspace/nuxt-ember/src/plugins/gtm.client.ts";
import posthog_client_uL8DpUhgsW from "/Users/dreckr/workspace/nuxt-ember/src/plugins/posthog.client.ts";
import routerAuthGuard_lKxf5RBTPW from "/Users/dreckr/workspace/nuxt-ember/src/plugins/routerAuthGuard.ts";
import scroll_UUYEvXMkkq from "/Users/dreckr/workspace/nuxt-ember/src/plugins/scroll.ts";
import sentry_h0fMjCG9AB from "/Users/dreckr/workspace/nuxt-ember/src/plugins/sentry.ts";
import subscription_vypBpg4JqQ from "/Users/dreckr/workspace/nuxt-ember/src/plugins/subscription.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_BqLkNUef68,
  plugin_8SbxDRbG6Y,
  chunk_reload_client_UciE0i6zes,
  payload_client_yVLowv6hDl,
  customer_ViIGNqCnbl,
  error_t9vrANEheK,
  fontAwesome_i1V8MuBbQI,
  gtm_client_Hp0PRXEnoA,
  posthog_client_uL8DpUhgsW,
  routerAuthGuard_lKxf5RBTPW,
  scroll_UUYEvXMkkq,
  sentry_h0fMjCG9AB,
  subscription_vypBpg4JqQ
]